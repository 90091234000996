import { forwardRef, useEffect, useState } from "react";
import type { Editor, Range } from "@tiptap/react";
import { isEmpty } from "lodash";
import { useEmojiMartData } from "@circle-react-shared/uikit/EmojiPickerV3/Pickers/EmojiMart/useEmojiMartData";
import { useInternalEditorState } from "@circle-react-shared/uikit/TipTap/internalEditorState";
import { useKeyboardHandler } from "@circle-react-uikit/TipTap/Extensions/CustomExtensions/hooks/useKeyboardHandler";
import { useFetchEmojis } from "@circle-react-uikit/TipTapBlockEditor/InlineEmojiExtension/hooks/useFetchEmojis";
import { EmojiItem } from "./EmojiItem";
import { getEmojiMart } from "./getEmojiMart";

export const EMOJI_LIST_ID = "emojiList";

interface EmojiPickerProps {
  editor: Editor;
  range: Range;
  query: string;
}

export const EmojiPicker = forwardRef(
  (
    { editor, range, query }: EmojiPickerProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const { setPopupsVisible } = useInternalEditorState();

    useEmojiMartData({
      options: {
        onSuccess: async (data: any) => {
          const module = await getEmojiMart();
          const { init } = module;
          void init({ data });
        },
      },
    });
    const { emojis } = useFetchEmojis(query);

    useEffect(() => {
      setPopupsVisible(true);
    }, [setPopupsVisible]);

    const insertEmoji = (emoji: any) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .insertContent(emoji.skins[0].native)
        .insertContent(" ")
        .run();
    };

    useEffect(() => {
      document
        .getElementById(`emoji-${emojis[selectedIndex]?.id}`)
        ?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
    }, [selectedIndex, emojis]);

    useKeyboardHandler({
      selectedIndex,
      setSelectedIndex,
      ref,
      items: emojis,
      selectItem: (index: number) => {
        const emoji = emojis[index];
        insertEmoji(emoji);
      },
    });

    if (isEmpty(emojis)) {
      return null;
    }

    return (
      <div
        ref={ref}
        className="border-primary bg-primary relative max-h-[50vh] min-w-[250px] max-w-[300px] overflow-auto rounded border py-1.5 text-sm shadow-md"
        id={EMOJI_LIST_ID}
      >
        {emojis.map((emoji, index) => {
          const isSelected = index === selectedIndex;
          return (
            <EmojiItem
              key={emoji.id}
              emoji={emoji}
              index={index}
              setSelectedIndex={setSelectedIndex}
              isSelected={isSelected}
              insertEmoji={insertEmoji}
            />
          );
        })}
      </div>
    );
  },
);

EmojiPicker.displayName = "EmojiPicker";
