import type { ComponentPropsWithoutRef, ReactNode } from "react";
import { Tab as HeadlessUITab } from "@headlessui/react";
import { classNames } from "@circle-react/helpers/twMergeWithCN";

interface TabProps extends ComponentPropsWithoutRef<"button"> {
  children: ReactNode;
  className?: string;
  wrapperClassName?: string;
  disabled?: boolean;
  isButtonType?: boolean;
  activeClassName?: string;
  hideInvisibleBlock?: boolean;
}

export const TabRoot = ({
  children,
  className = "",
  wrapperClassName = "",
  disabled = false,
  isButtonType = false,
  activeClassName = "",
  hideInvisibleBlock = false,
  ...props
}: TabProps) => (
  <HeadlessUITab
    as="div"
    className={classNames("flex", wrapperClassName)}
    data-testid="tab-div-wrapper"
  >
    {({ selected = false }) => (
      <button
        type={isButtonType ? "button" : "submit"}
        data-testid="tab-button"
        disabled={disabled}
        className={classNames(
          "inline-block h-10 whitespace-nowrap border-b-2 py-2 text-base leading-none",
          {
            "cursor-not-allowed": disabled,
            "cursor-pointer": !disabled,
            "border-darkest": selected,
            "hover:border-primary border-transparent transition-colors":
              !selected,
            [activeClassName]: selected,
          },
          className,
        )}
        {...props}
      >
        {/*
            Render the children again on a hidden span block with bold styles,
            so that the button minimum width is the same as the bold text width,
            preventing layout jumps on the X axis when the selected tab changes.
          */}
        {!hideInvisibleBlock && (
          <span
            className="invisible block h-0 overflow-hidden font-semibold"
            aria-hidden
          >
            {children}
          </span>
        )}
        <span
          className={classNames("block", {
            "text-dark font-semibold": selected,
            "text-light font-normal": !selected,
          })}
        >
          {children}
        </span>
      </button>
    )}
  </HeadlessUITab>
);
