import { forwardRef, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { useMapbox } from "@/react/hooks/useMapbox";
import type { FormInputProps } from "@circle-react-uikit/Form/FormInput";
import { TextSelect } from "../../../TextSelect";

export const LocationInput = forwardRef<HTMLInputElement, FormInputProps>(
  props => {
    const { name } = props;
    const { setValue, getValues } = useFormContext();
    const originalTextValue = getValues(name) || "";

    const {
      locationOptions,
      handleSuggestionSelect,
      selectedMapboxId,
      debouncedSearch,
      value,
      displayValue,
    } = useMapbox(originalTextValue);

    useEffect(() => {
      setValue(name, value);
    }, [value, name, setValue]);

    return (
      <TextSelect
        placeholder={t("filters.location_filter.search_location")}
        emptyMessage={t("filters.location_filter.no_locations_found")}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any -- needs more ts conversions
        options={locationOptions as any}
        onChange={handleSuggestionSelect}
        onSearchChange={debouncedSearch}
        value={selectedMapboxId}
        displayValue={displayValue}
      />
    );
  },
);

LocationInput.displayName = "LocationInput";
